import type {UserResponse} from "~/common/interfaces";
import {$useApiFetch} from "~/composables/useApiFetch";
import {endpoints} from "~/constants/endpoints";
import { useUserProfileStore } from "~/store/user/user_prifile.store.js";

export const useUserManagementApi = () => {

    const userProfileStore = useUserProfileStore()

    return {
        userPasswordReset: async (authType: string, identifier: string, new_password: string, request_id: string) => {
            return await $useApiFetch<UserResponse>(endpoints.user_management.password_reset, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    [authType]: identifier,
                    new_password,
                    request_id
                })
            })
        },
        userRegister: async (authType: string, identifier: string, country_code: number, password: string, request_id: string) => {
            return await $useApiFetch<UserResponse>(endpoints.user_management.register, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    [authType]: identifier,
                    country_code,
                    password,
                    request_id
                })
            })
        },
        userCreate: async (
            token: string,
            full_name: string,
            date_of_birth: string,
            gender: boolean,
            phone_number: string,
            email: string,
            role: string,
            specifications: []
        ) => {
            return await $useApiFetch<UserResponse>(endpoints.user_management.create, {
                method: 'POST',
                body: JSON.stringify({
                    full_name, date_of_birth, gender, phone_number, email, role, specifications
                })
            })
        },
        userUpdate: async (
            token: string,
            username: string,
            email: string,
            full_name: string,
            gender: boolean,
            role: string,
            city: number,
            notification_methods: [],
            notification_types: [],
            languages: []
        ) => {
            return await $useApiFetch<UserResponse>(endpoints.user_management.update, {
                method: 'POST',
                body: JSON.stringify({
                    username, email, full_name, gender, role, city, notification_methods, notification_types, languages
                })
            })
        },
        useUserDelete: async (password: string) => {
            return await $useApiFetch<UserResponse>(endpoints.user_management.delete, {
                method: 'DELETE',
                body: JSON.stringify({
                    password
                })
            })
        },
        updateUserContactInformation: async (authType: string, identifier: string, request_id: string, country_code: number) => {
            return await $useApiFetch<UserResponse>(endpoints.user_management.contact_information_update, {
                method: 'POST',
                body: JSON.stringify({
                    [authType]: identifier,
                    country_code,
                    request_id
                })

            })
        },
        getUserProfileApi: async (setToStore:boolean = true) => {
            const userdata = await $useApiFetch<UserResponse>(endpoints.user_management.get_profile, {
                method: 'GET',
            })
            if(setToStore){
                userProfileStore.setUserData(userdata)
            }
            return userdata
        }
    }
}