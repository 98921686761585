import {endpoints} from "~/constants/endpoints.js";
import {$useApiFetch} from "~/composables/useApiFetch";


export const sendFeedback = async (data: any) => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    // const endpoint = sprintf(endpoints.dictionaries.feedback)
    return await $useApiFetch(endpoints.dictionaries.feedback, {
        method: 'POST',
        body: data
    }).finally(() => isLoading.set(false))
}

export const fetchMessages = async () => {
    return await $useApiFetch(endpoints.user_management.unread_messages, {
        method: 'GET',
    })
}