// Универсальная функция для выполнения запросов с использованием $fetch и добавления заголовков авторизации
export function $useApiFetch<T>(
    request: Parameters<typeof $fetch<T>>[0], // Параметр запроса (URL или объект запроса)
    opts?: Parameters<typeof $fetch<T>>[1],  // Опции запроса, такие как заголовки и метод
) {
    const config = useRuntimeConfig(); // Получение конфигурации среды выполнения (например, базовый URL)
    const AuthStore = useAuthStore(); // Получение состояния авторизации пользователя
    const headers = opts?.headers || {}; // Инициализация заголовков, если они не указаны

    // Если у пользователя есть токен доступа, добавляем его в заголовок авторизации
    if (AuthStore.Access) {
        headers.Authorization = `Bearer ${AuthStore.Access}`;
    }
    
    // Выполнение запроса с использованием $fetch, добавляя базовый URL и заголовки
    return $fetch<T>(request, {
        baseURL: config.public.baseURL, // Использование базового URL из конфигурации
        ...opts, // Передача остальных опций запроса
        headers, // Установка заголовков запроса
    });
}

// Универсальная функция для выполнения запросов с использованием useFetch и обработкой событий запроса и ответа
export function $useFetch<T>(
    url: Parameters<typeof useFetch<T>>[0], // Параметр URL запроса
    options?: Parameters<typeof useFetch<T>>[1], // Опции запроса, такие как заголовки и метод
) {
    const config = useRuntimeConfig(); // Получение конфигурации среды выполнения
    const AuthStore = useAuthStore(); // Получение состояния авторизации пользователя

    // Выполнение запроса с использованием useFetch
    return useFetch<T>(url, {
        baseURL: config.public.baseURL, // Использование базового URL из конфигурации

        // Обработчик события запроса
        onRequest({request, options}) {
            options.headers = options.headers || {}; // Инициализация заголовков, если они не указаны
            
            // Если у пользователя есть токен доступа, добавляем его в заголовок авторизации
            if (AuthStore.Access) {
                options.headers.Authorization = `Bearer ${AuthStore.Access}`;
            }
        },

        // Обработчик ошибок запроса (например, ошибки сети)
        onRequestError({request, options, error}) {
            // Здесь можно обработать ошибки запроса
        },

        // Обработчик события ответа (например, обработка данных ответа)
        onResponse({request, response, options}) {
            // Здесь можно обработать данные ответа
        },

        // Обработчик ошибок ответа (например, ошибки сервера)
        onResponseError({request, response, options}) {
            // Здесь можно обработать ошибки ответа
        },

        ...options, // Передача остальных опций запроса
    });
}