<template>
    <noscript>
        <!-- Если JavaScript отключен, отображается эта ссылка -->
        <a href="/" target="_blank">
            <!-- Изображение, которое используется для отслеживания посетителей через Histats -->
            <img src="//sstatic1.histats.com/0.gif?4882679&101" alt="" border="0">
        </a>
    </noscript>
</template>

<script setup>
// Код выполняется только на клиентской стороне (в браузере)
if (process.client) {
    var _Hasync = _Hasync || []; // Инициализация массива _Hasync, если он еще не определен

    // Добавление команд в массив _Hasync для инициализации и настройки отслеживания через Histats
    _Hasync.push(['Histats.start', '1,4882679,4,0,0,0,00010000']); // Инициализация отслеживания
    _Hasync.push(['Histats.fasi', '1']); // Опция для настройки отслеживания
    _Hasync.push(['Histats.track_hits', '']); // Отслеживание посещений

    (function () {
        var hs = document.createElement('script'); // Создание нового элемента <script>
        hs.type = 'text/javascript'; // Установка типа контента как JavaScript
        hs.async = true; // Установка асинхронной загрузки скрипта
        hs.src = ('//s10.histats.com/js15_as.js'); // Указание источника скрипта (внешний ресурс Histats)

        // Добавление скрипта в <head> или <body> документа для его выполнения
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hs);
    })();
}
</script>