import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45global from "/app/middleware/route.global.ts";
import user_45global from "/app/middleware/user.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  route_45global,
  user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  maintenance: () => import("/app/middleware/maintenance.ts")
}