import {watch} from 'vue'

import {configure, defineRule} from 'vee-validate'
import {localize, setLocale} from '@vee-validate/i18n'

import {
    required,
    between,
    confirmed,
    email,
    max,
    min_value,
    numeric,
    min,
} from '@vee-validate/rules'

import en from '@vee-validate/i18n/dist/locale/en.json'
import fr from '@vee-validate/i18n/dist/locale/ru.json'
import ar from '@vee-validate/i18n/dist/locale/ar.json'
import de from '@vee-validate/i18n/dist/locale/de.json'
import ru from '@vee-validate/i18n/dist/locale/ru.json'

function validUrl (value: string) {
    const regex = new RegExp('(https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?\\/[a-zA-Z0-9]{2,}|((https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?)|(https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})?');
    if (!regex.test(value)) {
        return 'Enter valid url.'
    }
    return true;
}

export default defineNuxtPlugin((nuxtApp) => {
    configure({
        generateMessage: localize({
            en,
            de,
            fr,
            ar,
            ru,
        }),
    })

    defineRule('required', required);
    defineRule('email', email);
    defineRule('between', between);
    defineRule('confirmed', confirmed);
    defineRule('numeric', numeric);
    defineRule('max', max);
    defineRule('min', min);
    defineRule('url', validUrl);
    defineRule('min_value', min_value);

    watch(nuxtApp?.$i18n?.locale, (val: string) => {
        switch (toValue(val)) {
            case 'en':
                setLocale('en');
                break;
            case 'fr':
                setLocale('fr');
                break;
            case 'de':
                setLocale('de');
                break;
            case 'ar':
                setLocale('ar');
                break;
            case 'ru':
                setLocale('ru');
                break;
        }
    }, {
        immediate: true
    });
})
