interface AuthCookie {
    access: string;
    refresh: string;
    is_auth: boolean;
}



export const useAuthStore = defineStore('auth', () => {
    const elAuthCookie = useCookie<AuthCookie>('auth')
    const isAuth = ref(elAuthCookie.value?.is_auth)
    const Access = ref(elAuthCookie.value?.access)
    const Refresh = ref(elAuthCookie.value?.refresh)

    function clear() {
        isAuth.value = false
        Access.value = ''
        Refresh.value = ''
    }

    return {
        isAuth,
        Access,
        Refresh,
        clear
    }
})

