import { useDictionaryStore } from "./dictionary.store";
import type { DialCode } from "~/types/dictionary";

export const useStatesStore = defineStore('States', () => {
    const DictionaryStore = useDictionaryStore();
    const EmailOrPhoneForm = ref(false);
    const FailedAttempts = ref(false);
    const UserForgotPassword = ref(false);
    const UserCreateAccount = ref(false);


    const IsErrorNotice = ref(false);
    const IsWarningNotice = ref(false);
    const IsSuccessNotice = ref(false);

    const IsUserExist = ref(0);
    const OTPIsVerify = ref(true);

    const UserEmail = ref('');
    const UserPhone = ref('');
    const RequestID = ref('');

    const CurrentDialCode = ref<DialCode | null>(null)

    async function setCurrentDialCode(){              
        if(!DictionaryStore.CodePhoneList.length){ //TODO: Стремная хрень с Typescript
            await DictionaryStore.fetchCodePhones()
        }
        let dialCode = DictionaryStore.CodePhoneList.find(item => item.code === 'ar')
        if(!dialCode){
            dialCode = DictionaryStore.CodePhoneList[0]
        }

        CurrentDialCode.value = dialCode
    }

    async function useClearCustomStates() {
        EmailOrPhoneForm.value = false

        FailedAttempts.value = false
        UserForgotPassword.value = false
        UserCreateAccount.value = false

        IsErrorNotice.value = false
        IsWarningNotice.value = false
        IsSuccessNotice.value = false

        IsUserExist.value = 0
        OTPIsVerify.value = true

        UserEmail.value = ''
        UserPhone.value = ''
        RequestID.value = ''

        await setCurrentDialCode()
    }


    return {
        EmailOrPhoneForm,

        FailedAttempts,
        UserForgotPassword,
        UserCreateAccount,

        IsErrorNotice,
        IsWarningNotice,
        IsSuccessNotice,

        IsUserExist,
        OTPIsVerify,

        UserEmail,
        UserPhone,
        RequestID,
        CurrentDialCode,

        useClearCustomStates,
        setCurrentDialCode

    }
})