import type {EducationTypeResponse} from "~/common/interfaces.js";
import {endpoints} from "~/constants/endpoints.js";
import {$useApiFetch, $useFetch} from "~/composables/useApiFetch";
import {sprintf} from "sprintf-js";

export const getEducationTypes = async (): Promise<EducationTypeResponse[]> => {
    return await $useApiFetch<EducationTypeResponse[]>(endpoints.dictionaries.education_types, {
        method: 'GET',
    })
}

export const getEducationTypeList = async (props?: Object): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.education_types, {
        method: 'GET',
        ...props
    })
}

//TODO: Возможно стоит использовать живой поиск для использования Elastic и поиска по синонимам
export const getSpecificationsList = async (props?: Object): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.specifications, {
        method: 'GET',
        ...props
    })
}

export const getSpecificationDetail = async (id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.dictionaries.specification, id)
    return await $useApiFetch(endpoint, {
        method: 'GET',
    })
}

export const getReviewTagList = async (props?: Object): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.review_tags, {
        method: 'GET',
        ...props
    })
}

export const getSpecificationID = async (id: number | string): Promise<any> => {
    return await $useApiFetch( `/dictionaries/specifications/${id}/`, {
        method: 'GET',
    })
}

export const getPhoneCodesList = async (props?: Object): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.phone_codes, {
        method: 'GET',
        ...props
    })
}

export const getRegionsList = async (): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.regions, {
        method: 'GET',
    })
}

export const getCitiesList = async (): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.cities, {
        method: 'GET',
    })
}

export const getMastersFilterList = async (specification_id: number): Promise<any> => {
    const endpoint = sprintf(endpoints.dictionaries.masters_filters, specification_id)
    return await $useFetch(endpoint, {
        method: 'GET',
    })
}

export const getMastersList = async (props?: Object): Promise<any> => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useFetch(endpoints.dictionaries.masters, {
        method: 'GET',
        ...props
    }).finally(() => isLoading.set(false))
}


export const getMastersRecentList = async (): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.mastersRecent, {
        method: 'GET'
    })
}

export const getTasksRecentList = async (): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.tasksRecent, {
        method: 'GET'
    })
}

export const getMasterPortfolio = async (master_id: number | string, portfolio_id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.dictionaries.master_portfolio, master_id, portfolio_id)
    return await $useFetch(endpoint, {
        method: 'GET',
    })
}


export const getKnowledgeBases = async (): Promise<any> => {
    const endpoint = sprintf(endpoints.dictionaries.knowledge_bases)
    return await $useFetch(endpoint, {
        method: 'GET',
    })
}

export const getKnowledgeBaseDetail = async (base_id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.dictionaries.knowledge_base_articles, base_id)
    return await $useFetch(endpoint, {
        method: 'GET',
    })
}

export const getArticleDetail = async (base_id: number | string, article_id: number | string): Promise<any> => {
    const endpoint = sprintf(endpoints.dictionaries.knowledge_base_article_id, base_id, article_id)
    return await $useFetch(endpoint, {
        method: 'GET',
    })
}


export const getSubscriptionPlans = async (props?: Object): Promise<any> => {
    return await $useFetch(endpoints.dictionaries.subscription_plans, {
        method: 'GET',
        ...props
    })
}
